import React from "react"
import { Link } from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout nav='fixed' footer={false}>
    <SEO title="404: Not found"  description='404'/>
    <div className="_404">
      <div className="_404-content">
      <h4 className="subheading">
        Fehler 404
      </h4>
      <h2 className="heading">
        Entschuldige, die <br /> Seite konnte nicht <br /> gefunden werden.
      </h2>
      <Link to='/' className="btn btn-green">
        Startseite
      </Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
